<style scoped>
.add-owner {
    float: right;
}

.ranges {
    max-height: 200px;
    overflow: scroll;
}
.modal-footer{
        display: none !important;
}
.progress-bar-container {
  position: relative;
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  height: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.progress-bar {
  height: 100%;
  background-color: #4caf50;
  width: 0;
  border-radius: 5px;
  transition: width 0.4s ease;
}

.progress-text {
  position: absolute;
  width: 100%;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
}
</style>
<template>
    <div>
        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myModal" centered no-close-on-backdrop no-close-on-esc size="lg" hide-footer @ok="submit">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5>Barcode/Luggage</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
       

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <b-row>
                    <b-col cols="12">
                        <!-- <b-alert show variant="danger" v-if="errosOcurs" class="p-1">{{ errosOcurs.message }}</b-alert> -->

                        <b-alert show variant="danger" v-if="errosOcurs">
                            <h4 class="alert-heading">
                                {{ errosOcurs.message }}
                            </h4>
                            <div class="alert-body">
                                <div v-for="(errosOcurs, field) in errosOcurs.errors" :key="field">
                                    <strong>{{ field }}:</strong>
                                    <ul>
                                        <li v-for="message in errosOcurs" :key="message">{{ message }}</li>
                                    </ul>
                                </div>
                            </div>
                        </b-alert>


                    </b-col>
                </b-row>
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">

                        <b-row>

                            <b-col cols="12">
                                <div v-if="isValidating" class="progress-bar-container">
                                    <div class="progress-bar" :style="{ width: progress + '%' }"></div>
                                    <div class="progress-text">{{ progress }}%</div>
                                </div>
                                <b-form-group labe-for="barcode" label="Barcode/Luggage">
                                    <validation-provider #default="{ errors }" name="Barcode/Luggage" rules="">
                                        <b-form-input id="barcode" name="barcode" v-model="range.barcode"
                                            :state="errors.length > 0 ? false : null" size="md" @keyup.enter.prevent="validateBarcode()" ref="barcode" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.barcode">{{
                                            serverErrors.barcode[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                         
                        </b-row>

                        <b-row v-if="tickets != null">
                            <b-col cols="4">
                                <h6>Barcode: {{ tickets.barcode }} </h6>
                            </b-col>
                            <b-col cols="4">
                                <h6>Luggage:  {{ tickets.luggage.barcode }}</h6>
                            </b-col>

                            <b-col cols="4">
                                <h6>Location: </h6>
                            </b-col>
                        </b-row>

                        <b-row v-if="tickets != null" class="mt-1">
                            <b-col cols="4">
                                <h6>Captured By: {{ tickets.capturedBy.firstName.toUpperCase() }} {{ tickets.capturedBy.lastName.toUpperCase() }} </h6>
                            </b-col>
                            <b-col cols="4">
                                <h6>Captured at:  {{ tickets.captured_at | moment('DD/MM/YYYY H:m:s')  }}</h6>
                            </b-col>

                            <b-col cols="4">
                                <h6>Mass: {{ tickets.mass }} Kg</h6>
                            </b-col>
                        </b-row>

                        <b-row v-if="tickets != null" class="mt-1">
                            <b-col cols="4">
                                <h6>PCN Number: {{ tickets.agreementNumber }} </h6>
                            </b-col>
                            <b-col cols="2">
                                <h6>House Glade:  {{ tickets.clientGrade  }}</h6>
                            </b-col>
                            <b-col cols="2">
                                <h6>National Grade:  {{ tickets.govGrade  }}</h6>
                            </b-col>

                            <b-col cols="4">
                                <h6>Grower Number: {{ tickets.owner.registrationNumber }}</h6>
                            </b-col>
                        </b-row>


                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->


             <!-- Filters -->
         
        <!-- ./Filters -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            
                            <b-button variant="primary" size="md" @click="invokeCreateForm()">
                                <span class="text-nowrap">Check Ticket Details</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BFormCheckbox,BBadge, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted, reactive } from '@vue/composition-api'

import checkTfStoreModule from '@/views/cromis/green_shipping/check_tf/checkTfStoreModule'
import useCheckTfBoxesList from '@/views/cromis/green_shipping/check_tf/useCheckTfBoxesList'


export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider, BFormCheckbox,BBadge,BAlert,
    },
    directives: {},
    watch: {
        userInput(newInput, oldInput) {
            // Update default value when user input changes
            if (!isNaN(newInput)) {
                this.defaultValue -= (newInput - oldInput);
            } else {
                // Handle case where userInput is NaN (e.g., after deleting the number)
                this.defaultValue = this.defaultValue;
            }
        },
    },
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const myRangeModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const errosOcurs = ref(null)
        const levels = ref(null)
        const companies = ref([])
        const tickets = ref(null)
        const children = ref([])
        const progress = ref(0);
        const isValidating = ref(false);
        const box = ref({
            id: null,
            number: null,
        
        })

        const range = ref({
            barcode: null,
        })


        const validateBarcode = async (index) => {
            isValidating.value = true;
            progress.value = 0;

            const updateProgress = () => {
            if (progress.value < 93) {
                 progress.value += 3; // Increment progress
                }
            };

            // Simulate progress update
            const progressInterval = setInterval(updateProgress, 500);


            await store.dispatch('cromis-box/validateTicket', {data: range.value.barcode })
                .then(response => {
                    if(response.data.tickets.length > 0){
                        tickets.value = response.data.tickets[0];
                        console.log(tickets.value)
                    }

                    clearInterval(progressInterval);
                    progress.value = 100;
                    setTimeout(() => {
                        isValidating.value = false;
                    }, 500);
                })
                .catch(error => {
                    saving.value = false
                    errosOcurs.value = error.response.data
                    clearInterval(progressInterval);
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                    setTimeout(() => {
                        isValidating.value = false;
                        progress.value = 0;
                    }, 500);
                })
        }


        const CROMIS_STORE_MODULE_NAME = 'cromis-box'
        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, checkTfStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {
           
        })




        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            resolveStatusVariant,
            // Filers
            locationFilter,
        } = useCheckTfBoxesList()

        // Form Methods
     
        const invokeCreateForm = () => {
            formSelections.value.map((form) => {
                form.model = null
            })

            box.value = {
                id: null,
                number: null,
               
            }

            myModal.value.show()
        }




        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (box.value.id === null || box.value.id === 0)
                handleCreate()
        }

        const handleCreate = async () => {

        }

     


        return {
            // Data
            selections,
            formSelections,
            levels,
            children,
            box,
            range,
            selectedOwner: null,
            addedElement: null,
            clientName: JSON.parse(localStorage.getItem('userData')).client,
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            resolveStatusVariant,

            // Methods

            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            myRangeModal,
            locationFilter,
            invokeCreateForm,
            submit,
            isFormValid,
            handleCreate,
            companies,
            tickets,
            errosOcurs,
            progress,
            isValidating,
            validateBarcode
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>