import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        list: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('received_boxes', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        validateTicket: (cntx, params) => {
            console.log( params.data)
            return new Promise((resolve, reject) => {
                axios.get('tickets_min', { params: {
                    "query": params.data
                } })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        show: (cntx, { id }) => {
            axios.get(`boxes/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
        },
        create: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post('received_boxes', params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        update: (cntx, params) => {
            console.log(params)
            return new Promise((resolve, reject) => {
                axios.put(`boxes/${params.id}`, params.data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },

        range: (cntx, params) => {
            console.log(params)
            return new Promise((resolve, reject) => {
                axios.put(`ranges/${params.id}`, params.data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        remove: (cntx, id) => {
            return new Promise((resolve, reject) => {
                axios.delete(`boxes/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        levels: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('levels', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        locations: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('locations', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        companies: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('companies', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        societies: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('societies', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    }
}
